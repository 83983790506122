<template>
  <div>
  <v-form @submit.prevent="login">
    <cookie-modal v-if="showCookiesModal" />
    <v-card-text class="login-text-fields" v-if="!hasInjectedAuthentication">
      <!-- interesting read on chrome autofill
        https://github.com/vuejs/vue/issues/7058-->

      <v-text-field
        v-disabled-icon-focus
        class="rounded-pill email-field"
        required
        label="E-Mail"
        :rules="emailRules"
        v-model="email"
        id="email"
        ref="email"
        outlined
        data-cy="email-input"
        autocomplete="off"
      >
      </v-text-field>

      <v-text-field
        v-disabled-icon-focus
        class="rounded-pill pass-field"
        required
        id="password"
        ref="password"
        label="Passwort"
        :rules="passwdRules"
        v-model="password"
        :type="showPw ? 'text' : 'password'"
        :append-icon="showPw ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        @click:append="showPw = !showPw"
        outlined
        data-cy="password-input"
        autocomplete="off"
      >
      </v-text-field>

      <p class="error--text" v-if="error">{{ error }}</p>
    </v-card-text>
    <v-card-text v-if="hasInjectedAuthentication && !loggedInBefore"
      >Einen Moment bitte, Sie werden angemeldet..</v-card-text
    >
    <v-card-text v-if="hasInjectedAuthentication && loggedInBefore"
      >Möchten Sie Sich erneut anmelden?</v-card-text
    >
    <v-card-actions v-if="hasInjectedAuthentication && loggedInBefore">
      <v-btn class="color-primary" @click="login">Erneut Anmelden</v-btn>
    </v-card-actions>
    <v-card-actions v-if="!hasInjectedAuthentication" data-cy="login-button">
      <v-btn
        class="rounded-pill pa-7 color-primary"
        type="submit"
        style="font-size: 1rem"
        depressed
        block
        >Anmelden</v-btn
      >
    </v-card-actions>
  </v-form>
  <loading-component :isLoading="isLoading" />
</div>
</template>

<script>
import CookieModal from "@/components/CookieModal.vue";
import LoadingComponent from "@/components/atoms/LoadingComponent.vue";
import { EventBus, emailRules, passwdRules } from "@/helpers";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  components: {
    CookieModal,
    LoadingComponent
  },
  data() {
    return {
      email: "",
      password: "",
      emailRules: emailRules,
      passwdRules: passwdRules,
      error: "",
      showPw: false,
      isAutoLogin: false,
      isLoading: false,
    };
  },
  props: ["dark"],
  mounted() {
    this.disableAutoComplete();
    EventBus.$once("autologin", (data) => {
      this.$store.dispatch("setAutoLogin", { ...data });
      /*
       * this logic could be used to updating the credentials
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      window.addEventListener(
        "updateExternalCredentials",
        function (e) {
          that.$store.dispatch("setAutoLogin", { ...e.detail });
        },
        { once: true }
      );
      */
    });
    const interval = window.setInterval(function () {
      // magic is here. If an external source adds this variable to global scope,
      // then it's value is taken via eventbus Vue Instance and saved to store
      // this component then performs actions based on it...
      const el = window._tbAppConfig;
      if (el) {
        EventBus.$emit("autologin", el);
        window.clearInterval(interval);
      }
    }, 200);
    window.setTimeout(function () {
      window.clearInterval(interval);
    }, 5000);
  },
  methods: {
    disableAutoComplete() {
      let elements = document.querySelectorAll('[autocomplete="off"]');

      if (!elements) {
        return;
      }

      elements.forEach(element => {
        element.setAttribute("readonly", "readonly");
        element.style.backgroundColor = "inherit";

        setTimeout(() => {
          element.removeAttribute("readonly");
        }, 500);
      });
    },
    login() {
      this.isLoading = true;
      this.$store
        .dispatch("login", {
          email: this.email.trim(),
          password: this.password,
        })
        .then((res) => {
          if (this.isAutoLogin) {
            this.isLoading = false;
            this.$store.dispatch("usedAutoLogin");
            this.dispatchNativeEvent("TrashButton:loggedIn");
          }
          if (
            this.$store.getters.isLoggedIn !== true &&
            this.$store.getters.isFirstLayerAuthorized
          ) {
            this.isLoading = false;
            this.$router.push("/company-login");
            return;
          }
          if (res.data.initialPassword) {
            this.isLoading = false;
            this.$router.push({ name: "AccountSettings" });
            return;
          }
          const loginTarget = this.$store.getters.afterLoginTarget;
          if (loginTarget) {
            this.isLoading = false;
            this.$store.dispatch("setAfterLoginTarget", null);
            this.$router.push(loginTarget);
            return;
          }
          this.isLoading = false;
          this.$router.push("/dashboard");
        })
        .catch((e) => {
          this.isLoading = false;
          this.error =
            "Das hat nicht geklappt, bitte überprüfen Sie Ihre E-Mail und Ihr Passwort auf Richtigkeit.";
          if (this.isAutoLogin) {
            this.dispatchNativeEvent("TrashButton:loginFail");
          }
        })
        .finally(() => {
          this.$store.dispatch("clearAutoLoginState");
        });
    },
    dispatchNativeEvent(eventName) {
      const event = new Event(eventName, {
        bubbles: true,
        cancelable: true,
      });
      window.document.dispatchEvent(event);
    },
  },
  computed: {
    ...mapState({
      externalAppConfig: (state) => state.user.externalAppConfig,
      loggedInBefore: (state) => state.user.hasLoggedInByInjectedData,
      hasInjectedAuthentication: (state) =>
        state.user.externalAppConfig.email !== undefined,
    }),
    showCookiesModal() {
      return this.$cookies.isUndecided();
    },
  },
  watch: {
    externalAppConfig(vnew, vold) {
      this.email = vnew.email;
      this.password = vnew.password;
      if (vnew.email != vold.email && !this.loggedInBefore) {
        this.isAutoLogin = true;
        this.login();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.v-card__text p {
  text-align: left !important;
}

.v-btn.forget-Password-Button .v-btn__content {
  color: #b3deeb;
}

.v-input__icon {
  color: #ffffff !important;
}

.login-text-fields.v-card__text {
  padding: 7px 7px 0 7px;
}

.login-text-fields.v-card__text .v-input {
  border-radius: 7px;
  padding-bottom: 15px;
}

.v-text-field--outlined :deep(legend) {
  width: 0 !important;
}
.v-input :deep(.v-text-field__slot) {
  margin: 0 15px 0 15px;
}
.v-input :deep(.v-label--active) {
  margin: 3px 0 10px 0;
  padding-bottom: 20px;
}
.v-input :deep(input) {
  margin: 7px 0 0 0;
}

.v-card__actions {
  padding: 0 8px 8px 8px;
}

.v-text-field :deep(.v-label--active) {
  transform: translateY(-15px) scale(0.75) !important;
}

.pass-field :deep(.v-input__slot) {
  padding-right: 20px !important;
}
.mobile-screen .login-text-fields input:-webkit-autofill,
.mobile-screen .login-text-fields input:-webkit-autofill:hover,
.mobile-screen .login-text-fields input:-webkit-autofill:focus,
.mobile-screen .login-text-fields input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s;
  transition: background-color 5000s;
  -webkit-text-fill-color: #000000 !important;
}
</style>
