
import Vue from "vue";
import { mapState } from "vuex";
import CompanyLoginCard from '../components/CompanyLoginCard.vue';
import LoadingComponent from "../components/atoms/LoadingComponent.vue";

export default Vue.extend({
  name: "CompanyLogin",
  props: ["dark"],
  data: () => {
    return {
      showDialog: true,
      isLoading: false,
    };
  },
  components: { CompanyLoginCard, LoadingComponent },
  computed: {
    ...mapState({
      companyHandler: (state: any) => state.user.companyHandler,
      user: (state: any) => state.user.user,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.width < 800;
    },
  },
  methods: {
    loginToCompany(selectedCompany: number) {
      this.isLoading = true;
      const payload = {
        email: this.user.email,
        userId: this.user.id,
        companyId: selectedCompany,
      };
      this.$store.dispatch("loginToCompany", payload).then(() => {
        if (this.user.initialPassword) {
          this.isLoading = false;
          this.$router.push({ name: "AccountSettings" });
          return;
        }
        const loginTarget = this.$store.getters.afterLoginTarget;
        if (loginTarget) {
          this.isLoading = false;
          this.$store.dispatch("setAfterLoginTarget", null);
          this.$router.push(loginTarget);
          return;
        }
        this.isLoading = false;
        this.$router.push({ name: "Dashboard" });
      });
    },
  },
});

